import { DateTime } from 'luxon';


export type DisplayDateType = 'day' | 'month' | 'year' ;
export type DisplayType = DisplayDateType | 'datetime';
export const formatMapping: Record<DisplayType, string> = {
  datetime: 'dd MMM yyyy hh:mm a',
  day: 'dd MMM yyyy',
  month: 'MMM yyyy',
  year: 'yyyy'
}
function ensureMillisecond(input: number | string) {
  if (typeof input === 'number') return input;

  const dt = new Date(input);
  if (dt instanceof Date && !isNaN(dt.getTime())) return dt.getTime();
  return 0;
}

/**
 * Method for displaying timestamps (e.g. date modified, etc.)
 */
export function displayTimestamp(isoDateStr : string) {
  const date = DateTime.fromISO(isoDateStr);
  return date.isValid ? date.toRelativeCalendar() : "-";
}

export function displayTimestampWithFormat(dateStr : string, format: string) {
  const date = DateTime.fromFormat(dateStr, format);
  return date.isValid ? date.toRelativeCalendar() : "-";
}

export function displayTimestampFromMillis(dateInt : number) {
  const date = DateTime.fromMillis(ensureMillisecond(dateInt));
  return date.isValid ? date.toRelativeCalendar() : "-";
}


export function toGeneralizedDisplayedDateTimeFormat(date: DateTime, format = formatMapping.datetime) {
  return date.isValid ? date.toFormat(format) : null;
}

export function toGeneralizedDisplayedDateFormat(date: DateTime, mode: DisplayType = 'day') {
  return toGeneralizedDisplayedDateTimeFormat(date, formatMapping[mode]);
}

export function displayDate(isoDateStr: string, mode: DisplayType = 'day') {
  const date = DateTime.fromISO(isoDateStr);
    
  return toGeneralizedDisplayedDateFormat(date, mode);
}

export function displayDateTimeFromMillis(dateInt : number | string, mode: DisplayType = 'datetime') {
  const date = DateTime.fromMillis(ensureMillisecond(dateInt));
  return toGeneralizedDisplayedDateTimeFormat(date, formatMapping[mode]);
}


export function displayDateFromMillis(dateInt : number, mode: DisplayType = 'day') {
  const date = DateTime.fromMillis(ensureMillisecond(dateInt));
  return toGeneralizedDisplayedDateFormat(date, mode);
}

export function displayDateFromDateFormat(dt: string | number, mode: DisplayType = 'day') {
  const _dtLuxon =  DateTime.fromJSDate(new Date(dt));
  return toGeneralizedDisplayedDateFormat(_dtLuxon, mode);
}