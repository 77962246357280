import Components from "./components";
import "./styles/index.scss";

export const plugin = {
  install(app) {
    Object.entries(Components).forEach(([name, component]) => {
      app.component(name, component);
    });
  },
};

export { plugin as NtpCommon };
export default plugin;
