import { createApp } from "vue";
import VueDOMPurifyHTML from "vue-dompurify-html";
import "./styles/index.scss";
import App from "./App.vue";
import router from "./routes";
import store from "./stores";
import { NtpCommon } from "@ntprsrf-frontend/common/src/lib.cjs";
import {
  normalizeRequestInterceptor,
  handleErrorInterceptor,
  credentialInterceptor,
} from "@ntprsrf-frontend/common/src/utils/axios";
import { i18nextInitialized } from "./utils/localization";
import i18next from "i18next";
import I18NextVue from "i18next-vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { createHead } from '@unhead/vue'

const app = createApp(App);
const head = createHead();

normalizeRequestInterceptor();
handleErrorInterceptor();
credentialInterceptor();

i18nextInitialized.then(() => {
  app
    .use(store)
    .use(router)
    .use(VueDOMPurifyHTML)
    .use(NtpCommon)
    .use(VueReCaptcha, {
      siteKey: import.meta.env.VITE_CAPTCHA_KEY,
    })
    .use(I18NextVue, { i18next })
    .use(head)
    .mount("#app");
});
