<template>
  <DatePicker class="inline-block h-full w-full"
    v-model="dateInputValue"
    @input="handleChange"
    @blur="handleBlur"
    :min-date="minDate"
  >
    <template v-slot="{ inputValue, inputEvents }" class="z-50">
      <div
        class="NtpDateInput__InputContainer"
      >
        <input
          :disabled="props.disabled"
          class="NtpDateInput__Input"
          :value="displayDateFromDateFormat(inputValue)"
          v-on="inputEvents"
        />
        <NtpXCircleIcon class="cursor-pointer" v-if="dateInputValue" @click="resetField()" color="secondary-400" size="md" aria-hidden="true" />
        <NtpCalendarIcon color="secondary-400" size="md" aria-hidden="true" />
      </div>
    </template>
  </DatePicker>
</template>

<script setup lang="ts">
import "../styles/index.scss";
import { toRef } from "vue";
import { DatePicker } from "v-calendar";
import "v-calendar/dist/style.css";
import { NtpCalendarIcon, NtpXCircleIcon } from './icons/outline'
import { useField } from "vee-validate";
import { displayDateFromDateFormat } from '../utils/displayDateTime';

const props = withDefaults(defineProps<{
  value?: Date;
  name: string;
  disabled: boolean;
  minDate?: Date;
}>(), {
  value: undefined,
  disabled: false
});

const name = toRef(props, 'name');

const {
  value: dateInputValue,
  handleBlur,
  handleChange,
  resetField
} = useField(name, undefined, {
  initialValue: props.value,
});
</script>
<style lang="scss" scoped>
.NtpDateInput__InputContainer {
  @apply flex items-center bg-white text-gray-700 h-10 w-full py-1 px-2 appearance-none border rounded-md border-gray-300 gap-1 shadow-sm;

  > svg {
    min-width: 24px;
  }
}

.NtpDateInput__Input {
  @apply w-full focus:outline-none;
}
</style>
